import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Dropdown,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Divider,
    Popover,
    Checkbox,
    InputNumber, Descriptions, Image, Timeline, Tooltip, Radio, Table, Tabs
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    DatabaseOutlined, ReloadOutlined, ZoomOutOutlined, EditFilled, ToolOutlined, CarOutlined, SearchOutlined
} from "@ant-design/icons";


import axiosInstance from "services/axios";

import DamageReportTable from "./DamageReportTable";
import MultiImageRenderer from "./MultiImageRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import MultiImageRendererV2 from "../MultiImageRendererV2/MultiImageRendererV2";
import {useNavigate} from "react-router-dom";
import {getColumnDefs} from "../MonitoringDashboard/MonitoringDashboardColumns";

const {Search} = Input;
const {Text, Paragraph, Link} = Typography;
const {Option} = Select;


const ImageTool = (props) => {

    const [UAID, setUAID] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('1');
    const [allImages, setAllImages] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const [infoData, setInfoData] = useState(null);
    const [plotData, setPlotData] = useState(null);
    const [showPredictionModal, setShowPredictionModal] = useState(false);

    const [feedbackHelper, setFeedbackHelper] = useState(null);
    const [feedbackImages, setFeedbackImages] = useState(null);
    const [feedbackImagesAI, setFeedbackImagesAI] = useState(null);
    const [feedbackImagesLabels, setFeedbackImagesLabels] = useState(null);

    const [selectedDocs, setSelectedDocs] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [inspectionData, setInspectionData] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [predictionData, setPredictionData] = useState(null);
    const [inspectionPredictionData, setInspectionPredictionData] = useState(null);


    const urlBase = "https://webapp.carvaloo.com/ereignisse"

    useEffect(() => {
        document.title = 'Image Tool';
        // extract UAID from URL path
        const path = window.location.pathname;

        if (path.includes("UAID")) {
            const parts = path.split("-");
            if (parts.length === 3 && parts[2].length === 12) {
                setUAID(path.substring(path.indexOf("UAID")));
            } else {
                notification['error']({
                    message: 'Not a valid UAID',
                    duration: 5
                });
            }
        }
    }, []);

    const getInfo = () => {
        axiosInstance.post(
            '/api/anomaly_tool/info',
            {
                UAID: UAID,
            })
            .then(res => {
                setInfoData(res.data)
                setCustomer(res.data.customer)
                setCustomerId(res.data.customer_id)
            })
            .catch(error => {
                    console.log(error);
                    setInfoData(null)
                    notification['error']({
                        message: 'Error in get AnomalyInfo',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const getPlots = () => {
        axiosInstance.post(
            '/api/anomaly_tool/plots',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                setPlotData(res.data)
            })
            .catch(error => {
                console.log(error);
                setPlotData(null)
                notification['error']({
                    message: 'Error in get AnomalyPlots',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const getImages = () => {
        axiosInstance.post(
            '/api/image_tool/images',
            {
                UAID: UAID,
                all_images: allImages,
                customer: customer,
            })
            .then(res => {
                console.log(res.data)

                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])
                // res.data.vehicle_images.push(res.data.vehicle_images[0])

                setCustomerId(res.data.customer_id)

                setInspectionData(res.data.vehicle_images)

                setFeedbackHelper(res.data.feedback_images)
                const feedback_images = res.data.feedback_images;
                const uriSasFeedbackImages = feedback_images.map(image => image.uri_sas);
                setFeedbackImages(uriSasFeedbackImages);
                const uriSasFeedbackImagesAI = feedback_images.map(image => image.ai_image);
                setFeedbackImagesAI(uriSasFeedbackImagesAI)
                const labelsFeedbackImages = feedback_images.map(image => image.id);
                setFeedbackImagesLabels(labelsFeedbackImages)
                const allDocIds = feedback_images.map(image => image.id); // Flatten all doc_ids into a single array
                setSelectedDocs(allDocIds);
            })
            .catch(error => {
                console.log(error);
                setInspectionData(null)
                notification['error']({
                    message: 'Error in get AnomalyImages',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const getFeedbacks = () => {
        axiosInstance.post(
            '/api/image_tool/user_feedbacks',
            {
                UAID: UAID,
                customer: customer,
            })
            .then(res => {
                setFeedbackData(res.data.data)
                // setFeedbackData([])
                console.log(res.data.data)
            })
            .catch(error => {
                    console.log(error);
                    setFeedbackData(null)
                    notification['error']({
                        message: 'Error in get AnomalyFeedbacks',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const getPredictions = () => {
        axiosInstance.post(
            '/api/image_tool/image_ai_predictions',
            {
                UAID: UAID,
                customer: customer,
            })
            .then(res => {
                setPredictionData(res.data.damages)
                console.log(res.data.damages)
            })
            .catch(error => {
                    console.log(error);
                    setPredictionData(null)
                    notification['error']({
                        message: 'Error in get AnomalyAIPredictions',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    useEffect(() => {

        const index = parseInt(activeTab) - 1;

        if (inspectionData && customer && inspectionData[index]) {

            axiosInstance.post(
                '/api/image_tool/image_ai_predictions',
                {
                    customer: customer,
                    UAID: inspectionData[index].details,
                    type: inspectionData[index].type
                })
                .then(res => {
                    setInspectionPredictionData(res.data.damages)
                    console.log(res.data.damages)
                })
                .catch(error => {
                        console.log(error);
                        setInspectionPredictionData(null)
                        notification['error']({
                            message: 'Error in get AnomalyAIPredictions',
                            description: (
                                <>
                                    {error.message + ':'}<br/>
                                    {error.response && error.response.data && error.response.data.message}
                                </>
                            ),
                        });
                    }
                )
        }
    }, [activeTab, inspectionData, customer]);

    useEffect(() => {
        if (UAID !== null) {
            if (UAID.includes("UAID")) {
                getInfo()
                getPlots()
            }
            if (customer) {
                getFeedbacks()
                getImages()
                getPredictions()
            }
        } else {
            setInfoData(null)
        }
    }, [UAID, customer, allImages])

    const onSearch = (value) => {
        if (value) {
            const valueStr = value.trim()
            const parts = valueStr.split("-");
            if (valueStr.includes("UAID") && parts.length === 3 && parts[2].length === 12) {
                setUAID(valueStr)
                navigate(`/image_tool/${valueStr}`);
            } else if (customer !== null) {
                setUAID(valueStr)
                navigate(`/image_tool/${valueStr}`);
            } else if (customer === null && value) {
                notification['warning']({
                    message: 'Please set a customer if not searching for a UAID',
                });
                navigate(`/image_tool/${valueStr}`);
            } else {
                setUAID(null)
                navigate('/image_tool');
            }
        }
    }

    const onSelectFeedback = (event_id, isChecked) => {
        const item = feedbackData.find(i => i.id === event_id);
        if (!item) return; // Exit if no item is found

        setSelectedDocs(prevSelectedDocs => {
            let updatedSelectedDocs = [...prevSelectedDocs];

            if (isChecked) {
                // Add docIds that are not already in selectedDocs
                item.doc_ids.forEach(docId => {
                    if (!updatedSelectedDocs.includes(docId)) {
                        updatedSelectedDocs.push(docId);
                    }
                });
            } else {
                // Remove docIds from selectedDocs
                updatedSelectedDocs = updatedSelectedDocs.filter(docId => !item.doc_ids.includes(docId));
            }

            setFeedbackImages(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.uri_sas))
            setFeedbackImagesAI(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.ai_image))
            setFeedbackImagesLabels(feedbackHelper.filter(image => updatedSelectedDocs.includes(image.id)).map(image => image.id))

            return updatedSelectedDocs;
        });
    }

    const onTabSelect = (value) => {
        setActiveTab(value)
    }

    const handleUpdate = (id, column, value) => {

        axiosInstance.post(
            '/api/image_tool/image_ai_predictions/update',
            {
                customer: customer,
                id: id,
                column: column,
                value: value
            })
            .then(res => {
                getPredictions()
            })
            .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updating AnomalyAIPredictions',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                }
            )
    }

    const onChangeTimeSwitch = (checked) => {
        setAllImages(checked);

        // if (checked) {
        //     gridRef.current.api.setRowData();
        //     columnDefs = hideTrendView(columnDefs, false)
        //     setColumnDefsGrid(columnDefs)
        // } else {
        //     columnDefs = hideTrendView(columnDefs, true)
        //     setColumnDefsGrid(columnDefs)
        // }
    }

    const customerList = [
        {
            value: 'bolt',
            label: 'bolt'     // Assign the string to the label property}
        },
    ]

    const handleSelectChange = (value) => {
        setCustomer(value)
    };

    return (<>
            <Modal
                open={loading}
                closable={false}
                footer={null}
                centered={true}
                mask={true}
                maskClosable={false}
                bodyStyle={{backdropFilter: 'blur(3px)'}}
            >
                <div>
                    <Spin style={{marginRight: 20}} size="large"/>
                    Loading
                </div>
            </Modal>
            <Modal
                open={showPredictionModal}
                closable={true}
                footer={null}
            >
                <div>
                    {selectedImage}
                </div>
            </Modal>
            <Row gutter={8}>
                <Col span={8} style={{height: '100%'}}>
                    <Card
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            border: '1px solid #e8e8e8',
                            marginBottom: 8
                        }}
                        styles={{
                            body: {
                                padding: '4px',
                                display: 'flex',
                                flexDirection: "column",
                                flex: "1 1 auto"
                            }
                        }}
                    >
                        <Row>
                            <Col span={22}>
                                <Search
                                    placeholder="search for Event"
                                    onSearch={onSearch}
                                    allowClear={true}
                                    style={{
                                        width: 275,
                                        marginTop: 4,
                                        marginBottom: 4
                                    }}
                                />
                                <Select
                                    defaultValue={'Customer'}
                                    onChange={(value) => handleSelectChange(value)}
                                    style={{width: 125, marginTop: 4, marginLeft: 16}}
                                >
                                    {customerList.map(customer => (
                                        <Option key={customer.value} value={customer.value}>
                                            {customer.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={2}>
                                <Switch
                                    style={{marginTop: '8px'}}
                                    onChange={onChangeTimeSwitch}
                                    checkedChildren="all"
                                    unCheckedChildren="all"
                                    checked={allImages}
                                    // disabled={true}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            border: '1px solid #e8e8e8',
                            marginBottom: 8,
                            minHeight: 388,
                        }}
                        styles={{
                            body: {
                                padding: '4px',
                                display: 'flex',
                                flexDirection: "column",
                                flex: "1 1 auto"
                            }
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <Descriptions bordered size={'middle'}>
                                    <Descriptions.Item label="Customer">{infoData?.customer}</Descriptions.Item>
                                    <Descriptions.Item label="VTI">{infoData?.vehicle_type}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions bordered size={'middle'}>
                                    <Descriptions.Item label="UTC Time">{infoData?.utc_time}</Descriptions.Item>
                                    <Descriptions.Item label="UTC Date">{infoData?.utc_date}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions bordered size={'middle'}>
                                    <Descriptions.Item label="Time">{infoData?.time}</Descriptions.Item>
                                    <Descriptions.Item label="Date">{infoData?.date}</Descriptions.Item>
                                </Descriptions>
                                {/*<Descriptions bordered size={'middle'}>*/}
                                {/*    <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>*/}
                                {/*    <Descriptions.Item label="City">{data?.city}</Descriptions.Item>*/}
                                {/*</Descriptions>*/}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 16}}>
                            <Col span={16}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <Image
                                        // style={{height: "300px"}}
                                        src={plotData?.uaid_image}
                                        name={'uaid_image'}
                                    />
                                </div>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    {/*<div style={{*/}
                                    {/*    height: "160px",*/}
                                    {/*    display: "flex",*/}
                                    {/*    justifyContent: "center",*/}
                                    {/*    alignItems: "center",*/}
                                    {/*}}>*/}
                                    {/*    <Image*/}
                                    {/*        style={{height: "50px", marginLeft: 32}}*/}
                                    {/*        src={plotData?.trip_image}*/}
                                    {/*        name={'trip_image'}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div style={{
                                        height: "200px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <Image
                                            src={plotData?.direction_image}
                                            name={'direction_image'}
                                            // style={{height: 200}}
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            border: '1px solid #e8e8e8',
                            marginBottom: 8
                        }}
                        styles={{
                            body: {
                                padding: '4px',
                                display: 'flex',
                                flexDirection: "column",
                                flex: "1 1 auto"
                            }
                        }}
                    >
                        <div style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            minHeight: 100,
                            maxHeight: '380px',
                            marginLeft: 8,
                            marginTop: 8
                        }}>
                            {feedbackData &&
                                <>
                                    <Row>
                                        <Text style={{fontWeight: 500, fontSize: 16}}>
                                            Feedbacks
                                        </Text>
                                    </Row>
                                    {feedbackData.length > 0 &&
                                        <Timeline style={{marginTop: 20, marginLeft: 20, minHeight: 298}}>
                                            {feedbackData.map((item) => (
                                                <Timeline.Item
                                                    key={item.id}
                                                >
                                                    <Row>
                                                        <Col span={14}>
                                                            <Row style={{marginBottom: 4}}>
                                                                {item.created_timestamp.replace('T', ' - ').slice(0, 21)}
                                                                <Text style={{fontWeight: 500, marginLeft: 16}}>
                                                                    Feedback Id: {item.id}
                                                                </Text>
                                                                {/*<Popover*/}
                                                                {/*    title={'Comment'}*/}
                                                                {/*    trigger="click"*/}
                                                                {/*    content={*/}
                                                                {/*        <div style={{width: 600}}>*/}
                                                                {/*            <Text>*/}
                                                                {/*                {item.Comment}*/}
                                                                {/*            </Text>*/}
                                                                {/*        </div>*/}
                                                                {/*    }*/}
                                                                {/*    placement={'right'}*/}
                                                                {/*>*/}
                                                                {/*    <Button size={"small"} icon={<EditFilled/>}*/}
                                                                {/*            style={{marginLeft: 10}}>*/}
                                                                {/*        Comment*/}
                                                                {/*    </Button>*/}
                                                                {/*</Popover>*/}
                                                            </Row>
                                                            <Text>
                                                                {item.matching ? 'Damage' : 'No Damage'}
                                                            </Text>
                                                            <Divider type={'vertical'}
                                                                     style={{border: '1px solid gray'}}></Divider>
                                                            <Text>
                                                                {item.intensity ? 'Intensity: ' + item.intensity : 'Intensity: N/A'}
                                                            </Text>
                                                            <Divider type={'vertical'}
                                                                     style={{border: '1px solid gray'}}></Divider>
                                                            <Text>
                                                                {item.images ? '# Images: ' + item.images.split(',').length : '# Images: 0'}
                                                            </Text>
                                                        </Col>
                                                        <Col span={6} style={{marginTop: -16}}>
                                                            <CarPartRenderer value={item?.carPart_id}/>
                                                            {/*<Button size="small"*/}
                                                            {/*        style={{marginLeft: 6, marginTop: -16, marginBottom: 4}}*/}
                                                            {/*        onClick={() => onIgnoreVorfall(item.event_id)}>*/}
                                                            {/*    Ignore Incident*/}
                                                            {/*</Button>*/}
                                                        </Col>
                                                        <Col span={4} style={{marginTop: 14}}>
                                                            <Checkbox
                                                                style={{marginLeft: 32}}
                                                                onChange={(e) => onSelectFeedback(item.id, e.target.checked)} // Pass item.id and checked status
                                                                defaultChecked={true} // Keep it checked by default if needed
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Tooltip
                                                            title={item?.comments}
                                                            mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                                            mouseLeaveDelay={0}    // Hide immediately>
                                                        >
                                                            <Text>
                                                                {'Comment: ' + (item?.comments ? (item?.comments.length > 35 ? item?.comments.substring(0, 35) + '...' : item?.comments) : 'None')}
                                                            </Text>
                                                        </Tooltip>
                                                    </Row>
                                                    {/*<Text style={{color: item.location.lat ? 'black' : 'red'}}>*/}
                                                    {/*    Latitude: {item.location.lat ? item.location.lat : 'Not available'}*/}
                                                    {/*</Text>*/}
                                                    {/*<Divider type={'vertical'} style={{marginTop: 4, marginBottom: 4}}></Divider>*/}
                                                    <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                                </Timeline.Item>
                                            ))}
                                        </Timeline>
                                    }
                                    {feedbackData.length === 0 &&
                                        <Row style={{
                                            marginTop: 100,
                                            marginBottom: 196,
                                            marginLeft: 200
                                        }}>
                                            No data available
                                        </Row>
                                    }
                                </>
                            }
                        </div>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            border: '1px solid #e8e8e8',
                            marginBottom: 8
                        }}
                        styles={{
                            body: {
                                padding: '4px',
                                display: 'flex',
                                flexDirection: "column",
                                flex: "1 1 auto"
                            }
                        }}
                    >
                        <Row style={{marginTop: 8}}>
                            <Col span={10} style={{marginLeft: 8}}>
                                <Text style={{fontWeight: 500, fontSize: 16}}>
                                    Event Images
                                </Text>
                                {feedbackHelper &&
                                    <MultiImageRenderer
                                        data={feedbackImages}
                                        secondaryData={feedbackImagesAI}
                                        labels={feedbackImagesLabels}
                                        onSelect={setSelectedImage}
                                        maxImages={4}
                                        height={250}
                                        minHeight={250}
                                    />
                                }
                            </Col>
                            <Col span={13} style={{marginLeft: 32}}>
                                <Text style={{fontWeight: 500, fontSize: 16}}>
                                    AI Detected
                                </Text>
                                {predictionData &&
                                    <DamageReportTable
                                        data={predictionData.filter(row => selectedDocs.includes(parseInt(row.doc_id)) || row.doc_id === null)}
                                        updateData={handleUpdate}
                                    />
                                }
                            </Col>
                        </Row>
                        {/*<Row style={{marginTop: 8}}>*/}
                        {/*    <div style={{width: 500}}>*/}
                        {/*        <MultiImageRenderer*/}
                        {/*            data={feedbackImages} secondaryData={feedbackImagesAI}*/}
                        {/*            maxImages={4} height={250}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    {predictionData &&*/}
                        {/*        <DamageReportTable*/}
                        {/*            data={predictionData.filter(row => selectedDocs.includes(parseInt(row.doc_id)))}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*</Row>*/}
                    </Card>
                    <Row gutter={8}>
                        <Col span={16}>
                            {inspectionData &&
                                <Card
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                        border: '1px solid #e8e8e8',
                                        marginBottom: 8
                                    }}
                                    styles={{
                                        body: {
                                            padding: '4px',
                                            display: 'flex',
                                            flexDirection: "column",
                                            flex: "1 1 auto"
                                        }
                                    }}
                                >
                                    <Row style={{marginTop: 8, marginLeft: 8, marginRight: 8}}>
                                        <Text style={{fontWeight: 500, fontSize: 16}}>
                                            Vehicle Images
                                        </Text>
                                        {inspectionData.length > 0 ?
                                            <div style={{overflow: 'auto', maxHeight: 'calc(100% - 30px)'}}>
                                                <Tabs
                                                    onChange={onTabSelect}
                                                    defaultActiveKey="1"
                                                    items={inspectionData.map((item, i) => {
                                                        const id = String(i + 1);

                                                        const urlWebapp =
                                                            item.type === 'inspection'
                                                                ? `https://webapp.carvaloo.com/inspektion/${encodeURIComponent(item.details)}?organization=${encodeURIComponent(customerId)}`
                                                                : `https://webapp.carvaloo.com/ereignisse/${encodeURIComponent(item.details)}?organization=${encodeURIComponent(customerId)}`;

                                                        return {
                                                            key: id,
                                                            label: `${item.date.slice(0, 10)}`,
                                                            icon: item.type === 'event' ? <CarOutlined/> : item.type === 'reactive' ? <SearchOutlined/> :
                                                                <ToolOutlined/>,
                                                            children:
                                                                <>
                                                                    <Row>
                                                                        <Text
                                                                            style={{fontWeight: 500}}>
                                                                            <Link
                                                                                href={urlWebapp}
                                                                                // href={urlBase+"/"+item.details}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {item.details}
                                                                            </Link>
                                                                            {item.comment ? ` - ${item.comment}` : '- No comment'}
                                                                            {item.note ? ` - ${item.note}` : '- No note'}
                                                                        </Text>
                                                                    </Row>
                                                                    <Row style={{marginTop: 16}}>
                                                                        <Col span={14}>
                                                                            <MultiImageRenderer
                                                                                data={item.images.map(image => image.uri_sas)}
                                                                                secondaryData={item.images.map(image => image.ai_image)}
                                                                                maxImages={6}
                                                                                height={'100%'}
                                                                                minHeight={368}
                                                                                columnWidth={120}
                                                                            />
                                                                        </Col>
                                                                        <Col span={9}
                                                                             style={{marginLeft: 16}}>
                                                                            <DamageReportTable
                                                                                scroll={400}
                                                                                columns={[
                                                                                    {
                                                                                        title: 'Damage',
                                                                                        dataIndex: 'carPart_damaged',
                                                                                        key: 'carPart_damaged',
                                                                                        width: 120,
                                                                                    },
                                                                                    {
                                                                                        title: 'Typology',
                                                                                        dataIndex: 'typology',
                                                                                        key: 'typology',
                                                                                    },
                                                                                    {
                                                                                        title: 'New',
                                                                                        dataIndex: 'new_damage',
                                                                                        key: 'new_damage',
                                                                                        render: new_damage => (new_damage ? 'Yes' : 'No'),
                                                                                        width: 50,
                                                                                    },
                                                                                ]}
                                                                                data={inspectionPredictionData}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </>,
                                                        };
                                                    })}
                                                />
                                            </div>
                                            :
                                            <Row style={{
                                                width: 800,
                                                marginTop: 200,
                                                marginBottom: 249,
                                                marginLeft: 200,
                                            }}>
                                                No data available
                                            </Row>
                                        }
                                    </Row>
                                </Card>
                            }
                        </Col>
                        <Col>
                            <Card
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                    border: '1px solid #e8e8e8',
                                    marginBottom: 8
                                }}
                                styles={{
                                    body: {
                                        padding: '4px',
                                        display: 'flex',
                                        flexDirection: "column",
                                        flex: "1 1 auto"
                                    }
                                }}
                            >
                                <Text style={{fontWeight: 500, fontSize: 16, marginLeft: 32}}>
                                    Labels
                                </Text>
                            </Card>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ImageTool;