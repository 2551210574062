import React, {useState} from "react";
import {Image, Button, Typography, Col} from 'antd';

const {Text, Paragraph} = Typography;


const MultiImageRenderer = (props) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const plotData = props.data || [];
    const secondaryData = props.secondaryData || null;
    const maxImagesToShow = props.maxImages || 10;
    const height = props.height || 300;
    const minHeight = props.minHeight || 300;
    const columnWidth = props.columnWidth || 100;

    const secondaryDataValid = secondaryData !== null && secondaryData.length === plotData.length

    const totalImages = plotData.length;
    const totalPages = Math.ceil(totalImages / maxImagesToShow);

    const startIndex = currentPage * maxImagesToShow;
    const endIndex = Math.min(startIndex + maxImagesToShow, totalImages);
    const currentImages = plotData.slice(startIndex, endIndex);
    const currentSecondary = secondaryData ? secondaryData.slice(startIndex, endIndex) : null;
    const currentLabels = props.labels ? props.labels.slice(startIndex, endIndex) : null;

    const nextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentIndex(index);
        props.onSelect(props.labels[index])
    };

    // Handle preview visibility change
    // const handlePreviewChange = (visible, prevVisible) => {
    //     console.log('HERE')
    //
    //     setIsPreviewVisible(visible); // Update the visibility state
    //
    //     if (!visible && prevVisible) {
    //         props.onSelect(null)
    //         setCurrentIndex(0); // Reset index or perform other actions when closing
    //     }
    // };

    return (
        <>
            <div style={{
                height: height,
                minHeight: minHeight,
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // flexDirection: "column"
            }}>
                {totalImages === 0 ? (
                    <div>No images available</div>
                ) : (
                    <>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(auto-fill, minmax(${columnWidth}px, 1fr))`,
                            gap: '8px',
                            width: '100%',
                        }}>
                            {currentImages.map((src, index) => (
                                (secondaryDataValid && currentSecondary[index] !== null) ? (
                                    <Image.PreviewGroup
                                        key={`preview-group-${index}`} // Key for the Image.PreviewGroup
                                        items={[
                                            {
                                                src: src,
                                            },
                                            {
                                                src: currentSecondary[index],
                                            },
                                        ]}
                                    >
                                        <Col key={`col-preview-${index}`}>
                                            {currentLabels && (
                                                <Text style={{marginLeft: 35, fontWeight: 500}}>
                                                    {currentLabels ? currentLabels[index] : ''}
                                                </Text>
                                            )}
                                            <Image
                                                alt={'test'}
                                                key={`image-preview-${index}`}
                                                width="100%"
                                                height="auto"
                                                src={src}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: 180,
                                                    borderColor: 'red',
                                                    border: '4px solid rgb(8,142,215)'
                                                }}
                                                onClick={() => handleImageClick(index)} // Click event for secondary images
                                            />
                                        </Col>
                                    </Image.PreviewGroup>
                                ) : (
                                    <Col key={`col-no-preview-${index}`}>
                                        {currentLabels && (
                                                <Text style={{marginLeft: 35, fontWeight: 500}}>
                                                    {currentLabels ? currentLabels[index] : ''}
                                                </Text>
                                            )}
                                        <Image
                                            key={`image-no-preview-${index}`}
                                            width="100%"
                                            height="auto"
                                            src={src}
                                            style={{maxWidth: '100%', maxHeight: 180}}
                                            onClick={() => handleImageClick(index)} // Click event for secondary images
                                        />
                                    </Col>
                                )
                            ))}

                        </div>
                        <div style={{
                            marginTop: "10px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Button onClick={prevPage} disabled={currentPage === 0} style={{marginLeft: 135}}>
                                Previous
                            </Button>
                            <Button onClick={nextPage} disabled={currentPage === totalPages - 1}
                                    style={{marginRight: 292}}>
                                Next
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MultiImageRenderer;
