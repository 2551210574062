import React from 'react';
import {notification, Select, Table} from 'antd';
import axiosInstance from "../../services/axios";

const {Option} = Select;


const DamageReportTable = (props) => {

    const handleSelectChange = (value, record, column) => {
        props.updateData(record.id, column, value);
    };

    const defaultColumns = [
        {
            title: 'Doc ID',
            dataIndex: 'doc_id',
            key: 'doc_id',
            render: (text, record) => renderDropdown(text, record),
        },
        {
            title: 'Damage',
            dataIndex: 'carPart_damaged',
            key: 'carPart_damaged',
            width: 200,
        },
        {
            title: 'Typology',
            dataIndex: 'typology',
            key: 'typology',
        },
        // {
        //   title: 'Score',
        //   dataIndex: 'confidence',
        //   key: 'confidence',
        //   render: (text, record) => `${record.confidence} %`,
        // },
        {
            title: 'Area',
            dataIndex: 'surface',
            key: 'surface',
            render: (text, record) => `${record.surface} ${record.surface_unit}`
        },
        {
            title: 'New',
            dataIndex: 'new_damage',
            key: 'new_damage',
            render: new_damage => (new_damage ? 'Yes' : 'No'),
            width: 50,
        },
        {
            title: 'Matching',
            dataIndex: 'matching',
            key: 'matching',
            render: matching => (matching === null ? 'N/A' : matching),
        },
    ];

    const docIDs = props.data?.filter(row => row.doc_id !== null).map(row => row.doc_id)
    const docIDOptions = docIDs?.map(str => ({
        value: str,    // Assign the string to the value property
        label: str     // Assign the string to the label property
    }));

    // Function to render the correct dropdown based on the prediction method
    const renderDropdown = (text, record) => {
        if (record.doc_id === null && docIDOptions !== null && docIDOptions.length > 0) {
            return (
                <Select
                    defaultValue={text || 'Set'}
                    onChange={(value) => handleSelectChange(value, record, 'doc_id')}
                    style={{width: 75}}
                >
                    {docIDOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        } else {
            return record.doc_id; // In case no matching method, just return text.
        }
    };


    const columns = props.columns || defaultColumns
    const scroll = props.scroll || 200

    return (
        <Table
            dataSource={props.data}
            columns={columns}
            rowKey="id"
            pagination={false} // Change to true if you need pagination
            size={'small'}
            scroll={{y: scroll}} // Set max height to 300px with vertical scrolling
        />
    );
};

export default DamageReportTable;