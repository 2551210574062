import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button, Card, InputNumber, Select, Col, Modal, notification, Row, Typography, Input, Spin, Switch, Table
} from 'antd';

import Icon from "@ant-design/icons";
import {FaInfoCircle} from 'react-icons/fa';
import axiosInstance from "services/axios";

const {Search} = Input;
const {Title} = Typography;

const {Option} = Select;

const AnomalyInfoCard = (props) => {

    const [data, setData] = useState(null);

    const UAID = props.UAID

    const handleSelectChange = (value, record, column) => {
        const newData = [...data.anomaly_pred];
        const index = newData.findIndex(item => record.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {...item, [column]: value});

            setData({
                ...data,
                anomaly_pred: newData
            });

            const payload = {
                uaid: UAID,
                field: item['predict_method'],
                value: value,
            }
            axiosInstance.post('/api/anomaly_tool/prediction_labels/edit', payload)
                .then(function (response) {

                })
                .catch(function (error) {
                    console.log(error);
                    notification['error']({
                        message: 'Error labeling prediction',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                });
        }
    };

    // Dropdown options for each prediction method
    const drivingDirectionOptions = [
        {value: "V", label: "V"},
        {value: "VR", label: "VR"},
        {value: "VL", label: "VL"},
        {value: "R", label: "R"},
        {value: "RR", label: "RR"},
        {value: "RL", label: "RL"},
        {value: "N", label: "N"},
        {value: "None", label: "None"},
    ];

    const drivingSituationOptions = [
        {value: "Fahrt", label: "Fahrt"},
        {value: "Fahrt (Ausparken)", label: "Fahrt (Ausparken)"},
        {value: "Fahrt (Einparken)", label: "Fahrt (Einparken)"},
        {value: "Stillstand", label: "Stillstand"},
        {value: "Stillstand / Anfahren", label: "Anfahren"},
        {value: "None", label: "None"},
    ];

    const impactDirectionOptions = [
        {value: "hinten", label: "hinten"},
        {value: "hinten links", label: "hinten links"},
        {value: "hinten rechts", label: "hinten rechts"},
        {value: "vorne", label: "vorne"},
        {value: "vorne links", label: "vorne links"},
        {value: "vorne rechts", label: "vorne rechts"},
        {value: "links", label: "links"},
        {value: "links hinten", label: "links hinten"},
        {value: "links vorne", label: "links vorne"},
        {value: "rechts", label: "rechts"},
        {value: "rechts hinten", label: "rechts hinten"},
        {value: "rechts vorne", label: "rechts vorne"},
        {value: "None", label: "None"},
    ];

    // Function to render the correct dropdown based on the prediction method
    const renderDropdown = (text, record) => {
        if (record.predict_method.includes('driving_direction')) {
            return (
                <Select
                    defaultValue={text || 'Select'}
                    onChange={(value) => handleSelectChange(value, record, 'driving_direction')}
                    style={{width: 150}}
                >
                    {drivingDirectionOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        } else if (record.predict_method.includes('driving_situation__3')) {
            return (
                <Select
                    defaultValue={text || 'Select'}
                    onChange={(value) => handleSelectChange(value, record, 'driving_situation')}
                    style={{width: 150}}
                >
                    {drivingSituationOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        } else if (record.predict_method.includes('event_direction')) {
            return (
                <Select
                    defaultValue={text || 'Select'}
                    onChange={(value) => handleSelectChange(value, record, 'event_direction')}
                    style={{width: 150}}
                >
                    {impactDirectionOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        } else {
            return null; // In case no matching method, just return text.
        }
    };

    const columns = [
        {
            title: 'Method',
            dataIndex: 'predict_method',
            key: 'predict_method',
            width: 200,
        },
        {
            title: 'Prediction',
            dataIndex: 'prediction',
            key: 'prediction',
        },
        {
            title: 'Score',
            dataIndex: 'prediction_score',
            key: 'prediction_score',
            width: 80,
        },
        {
            title: 'Label', // New column with dropdown based on prediction method
            dataIndex: 'label',
            key: 'label',
            width: 170,
            render: (text, record) => renderDropdown(text, record),
            sorter: (a, b) => {
                // Define priority for sorting
                const priority = {
                    'driving_direction': 1,
                    'driving_situation__3': 2,
                    'event_direction': 3,
                };

                // Find priority values for both a and b
                const aPriority = Object.entries(priority).find(([key]) => a.predict_method.includes(key))?.[1] || 4; // Default lower priority
                const bPriority = Object.entries(priority).find(([key]) => b.predict_method.includes(key))?.[1] || 4; // Default lower priority

                // Step 2: First compare priorities, then sort by label if priorities are equal
                if (aPriority === bPriority) {
                    return (a.label || '').localeCompare(b.label || '');
                }

                return aPriority - bPriority; // Sort by priority
            },
        }
    ]

    const getDashboardValues = () => {
        axiosInstance.post(
            '/api/anomaly_tool/pred',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyPred',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getDashboardValues();
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    return (<>
            {/*<Modal*/}
            {/*  open={loading}*/}
            {/*  closable={false}*/}
            {/*  footer={null}*/}
            {/*  centered={true}*/}
            {/*  mask={true}*/}
            {/*  maskClosable={false}*/}
            {/*  bodyStyle={{ backdropFilter: 'blur(3px)' }}*/}
            {/*>*/}
            {/*  <Spin size="large" />*/}
            {/*</Modal>*/}
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={'Predictions'}
                bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={data?.anomaly_pred}
                    pagination={false}
                    scroll={{y: 320}}
                    style={{'maxHeight': '400px'}}
                />
            </Card>
        </>
    );
}

export default AnomalyInfoCard;
